<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-plant 
            type="search" 
            name="plantCd" 
            :disabled="plantDisabled"
            :changeItem="changeItem" 
            v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 작업기간 -->
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="작업기간"
            name="permitDates"
            v-model="searchParam.permitDates"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 신청업체 -->
          <c-vendor
            :plantCd="searchParam.plantCd"
            :changeItem="changeItem"
            label="신청업체"
            name="applicationDeptCd"
            v-model="searchParam.applicationDeptCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 발급업체 -->
          <c-vendor
            :plantCd="searchParam.plantCd"
            :changeItem="changeItem"
            label="발급업체"
            name="issuedDeptCd"
            v-model="searchParam.issuedDeptCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 허가서구분 -->
          <c-select
            type="search"
            codeGroupCd="SOP_PERMIT_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="permitTypeCd"
            label="허가서구분"
            v-model="searchParam.permitTypeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="작업허가서 목록"
      tableId="swp01"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      selection="single"
      rowKey="sopWorkPermitId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="선택" icon="check" @btnClicked="select" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safe-work-permit-load',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          plantCd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        permitDates: [],
        applicationDeptCd: null,
        issuedDeptCd: null,
        permitTypeCd: null,
        supplementWorkTypeCd: null,
        swpStepCd: 'SS00000020',
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '공사현장',
            align: 'center',
            sortable: true,
            style: 'width:140px',
          },
          {
            name: 'workSummary',
            field: 'workSummary',
            label: '작업개요',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:250px',
          },
          {
            name: 'swpStepName',
            field: 'swpStepName',
            label: '진행단계',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '결재진행단계',
            align: 'center',
            sortable: true,
            style: 'width:90px',
          },
          {
            name: 'permitDate',
            field: 'permitDate',
            label: '허가일',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            name: 'permitTypeName',
            field: 'permitTypeName',
            label: '허가서구분',
            align: 'center',
            sortable: true,
            style: 'width:70px',
          },
          {
            name: 'supplementWorkTypeNames',
            field: 'supplementWorkTypeNames',
            label: '보충작업',
            align: 'left',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'applicationDeptUserName',
            field: 'applicationDeptUserName',
            label: '신청업체',
            align: 'center',
            sortable: true,
            style: 'width:110px',
          },
          {
            name: 'issuedDeptUserName',
            field: 'issuedDeptUserName',
            label: '발급업체',
            align: 'center',
            sortable: true,
            style: 'width:110px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      detailUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    plantDisabled() {
      return Boolean(this.popupParam.plantCd)
    }
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.swp.workPermit.list.url
      this.detailUrl = selectConfig.sop.swp.workPermit.get.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '작업허가서';
      this.popupOptions.param = {
        sopWorkPermitId: row ? row.sopWorkPermitId : '',
        permitTypeCd: row ? row.permitTypeCd : '',
      };
      this.popupOptions.target = () => import(`${'./safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '허가서를 지정하세요.', // 허가서를 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$http.url = this.$format(this.detailUrl, selectData[0].sopWorkPermitId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$set(_result.data, 'swpStepCd', null)
          _result.data.workTime = _result.data.workStartTime && _result.data.workEndTime ?
            [_result.data.workStartTime, _result.data.workEndTime] : []
          this.$emit('closePopup', _result.data);
        },);
      }
    },
  }
};
</script>
